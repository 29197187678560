//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fetchRechargeList } from "@/api/charge";
import Pagination from "@/components/Pagination";
import "@/utils/global.js";
import { transCommonState, transRechargeType, transChannel, transChannelSvg } from "@/utils/util";
import { getToken } from "@/utils/cache";
import { downloadFun } from "@/utils/util";
import { fetchMemberList } from "@/api/member";
export default {
  name: "rechargeList",
  components: {
    Pagination: Pagination
  },
  data: function data() {
    return {
      // 查询字段
      searchForm: {
        page: 1,
        per_page: 20,
        start_time: "",
        end_time: "",
        keyword: "",
        filter: {
          status: "",
          channel: "",
          type: ''
        },
        time: "",
        min: "",
        max: ""
      },
      lineStatus: _global.lineStatus,
      failState: _global.failState,
      channelState: _global.channelState,
      // 表格数据
      dataList: [],
      // 总数
      totalPage: 0,
      tableLoading: false,
      submitLoading: false,
      selectCount: 0,
      selectList: [],
      memberDialog: false,
      dialogForm: {
        mobile: "",
        card: ""
      },
      totalNum: 0,
      typeState: [{
        value: 0,
        label: "其它"
      }, {
        value: 1,
        label: "充值"
      }, {
        value: 2,
        label: "消费"
      }, {
        value: 3,
        label: "扣款"
      }, {
        value: 4,
        label: "退款"
      }, {
        value: 5,
        label: "提现"
      }],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick: function onClick(picker) {
            var end = new Date();
            var start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      //搜索用户
      memberLoading: false,
      memberOptions: []
    };
  },
  created: function created() {
    this.searchForm.filter.member_id = this.$route.query ? this.$route.query.member_id : '';
    this.getTableData();
  },
  methods: {
    getTableData: function getTableData() {
      var _this = this;

      this.tableLoading = true;
      var data = this.searchForm;
      fetchRechargeList(data).then(function (response) {
        _this.tableLoading = false;
        _this.dataList = response.data;
        _this.totalPage = response.meta.total;
        _this.totalNum = response.meta.total_money;
      }).catch(function (err) {
        _this.tableLoading = false;

        _this.$message({
          type: "warning",
          message: err.data["message"]
        });
      });
    },
    handleSearch: function handleSearch() {
      this.getTableData();
    },
    handlePageCurrent: function handlePageCurrent(val) {
      console.log(val);
      this.searchForm.page = val;
      this.getTableData();
    },
    handlePageSize: function handlePageSize(val) {
      console.log(val);
      this.searchForm.per_page = val;
      this.searchForm.page = 1;
    },
    handleDownload: function handleDownload() {
      console.log("导出"); // 请求获取下载地址

      this.btnLoading = true;
      var data = this.searchForm;
      var url = process.env.VUE_APP_URL + "/recharges/download?token=".concat(getToken(), "&keyword=").concat(data.keyword || "", "&start_time=").concat(data.start_time, "&end_time=").concat(data.end_time, "\n        &filter[status]=").concat(data.filter.status || "", "\n        &filter[channel]=").concat(data.filter.channel || "", "\n        &filter[type]=").concat(data.filter.type || "", "\n        &filter[member_id]=").concat(data.filter.member_id || "");
      console.log(url);
      downloadFun(url, "充值记录.csv");
      this.btnLoading = false; // window.location.href = url
    },
    handleRefund: function handleRefund(data) {
      console.log("退款");
    },
    handleTime: function handleTime(val) {
      console.log("sss", val);

      if (val) {
        this.searchForm["start_time"] = val[0];
        this.searchForm["end_time"] = val[1];
      } else {
        this.searchForm["start_time"] = "";
        this.searchForm["end_time"] = "";
      }
    },
    resetForm: function resetForm(formName) {
      this.searchForm["start_time"] = "";
      this.searchForm["end_time"] = "";
      this.$refs[formName].resetFields();
    },
    //搜索用户
    remoteMember: function remoteMember(query) {
      var _this2 = this;

      if (query !== "") {
        this.memberLoading = true;
        setTimeout(function () {
          _this2.memberLoading = false;
          var data = {
            keyword: query
          };
          fetchMemberList(data).then(function (res) {
            console.log(res);
            _this2.memberOptions = res.data;
          });
        }, 100);
      } else {
        this.memberOptions = [];
      }
    },
    transRechargeType: transRechargeType,
    transCommonState: transCommonState,
    transChannel: transChannel,
    transChannelSvg: transChannelSvg
  }
};